import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, EmailBanner } from '../components'
import { ContactPageTemplate } from '../../../shared'

const ContactPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, subtitle, contactImage: image },
    },
  } = data

  return (
    <Layout>
      <ContactPageTemplate
        title={title}
        subtitle={subtitle}
        image={image}
        EmailBanner={EmailBanner}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        contactImage {
          childImageSharp {
            gatsbyImageData(width: 1300, quality: 100)
          }
        }
      }
    }
  }
`
